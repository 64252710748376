import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Pruritis Ani";

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "anal itching", "rectal itching", "rectal rash"]}
			type="article"
			description="Causes, management and explanation of Pruritis Ani - an itchy condition affecting the rectal/anus area"
		/>
		<H1>{title}</H1>
		<Par>
			Pruritus ani is a common condition which occurs in 5% of the population. It is more common in men and may be
			particularly troublesome at night or after passing a bowel action. Perianal contamination with moisture either
			from sweating or loose irritating stool may contribute to this problem. Excessive cleaning of the perianal area
			may exacerbate the problem by removing natural protective oils. Spicy foods and alcohol may cause loose stools and
			increase perianal moisture.
		</Par>
		<Par>
			When pruritus occurs in a family then pinworm infestation needs to be considered - these can frequently be seen
			with the naked eye on inspection of the perianal area. Itch in the perianal area can also be associated with the
			presence of haemorrhoids or anal fissures, infections or skin conditions such as psoriasis and eczema.
		</Par>
		<h2>Management of pruritus</h2>
		<ol>
			<li className="mt-3">
				Pruritus ani associated with an obvious rash - where there is an obvious rash in the perianal area then this
				should be diagnosed and treated appropriately. Referral to a dermatologist may be necessary. If there is
				bleeding or a change in bowel habit then referral to a gastroenterologist should be considered
			</li>
			<li className="mt-3">
				Pruritus with no rash - by far the commonest situation. It is important to keep the perianal area clean but at
				the same time limit trauma to the affected area.
			</li>
		</ol>
		<br />
		<ul>
			<li className="mt-3">
				Avoid using soap, washing the area gently with a soap substitute eg QV cleanser or aqueous cream
			</li>
			<li className="mt-3">Minimise scratching as much as possible as it may itching worse</li>
			<li className="mt-3">
				Limit moisture in the perianal area:
				<ul>
					<li className="mt-3">
						Use a non perfumed corn starch powder or barrier ointment such as Bepanthen to protect the skin. It is worth
						experimenting with both of these as different preparations suit different people
					</li>
					<li className="mt-3">
						Men should consider sitting down to urinate during the day and gently clean the perianal area if it feels
						moist
					</li>
					<li className="mt-3">
						If bowel actions tend to be loose then a bulking agent e.g. Metamucil taken twice daily may help firm up the
						bowel action and minimise moisture in the perianal area.
					</li>
				</ul>
			</li>
			<li className="mt-3">
				If moisture is a more resistant problem then consider using a male or female sanitary pad
			</li>
			<li className="mt-3">
				If the above measures fail then a mild anti-inflammatory cortisone agent such as Sigmacort 1% ointment or
				Egocort 1% cream (available over the counter) may be required. These are both very safe with a low incidence of
				side effects such as thinning of the skin. If these provide some benefit but do not completely clear the itch
				then short term use of a stronger cortisone preparation (e.g. Advantan) may be worthwhile. This should be used
				for 2-4 weeks. If the itch fails to settle with all these measures then referral to a dermatologist is worth
				considering.
			</li>
		</ul>
	</Blog>
);
